<template>
    <div class="home">
        <Header :mainHeader="true"/>
        <section id="settings-categories">
            <div class="container ">
              <h5 class="mb-3 fw-bold">Document Types</h5>
              <div class="panel">
                <label for="">1. Document Types</label>
                <div class="row mt-2">
                    <div class="col-md-2">
                        <input type="text"  class="form-control" placeholder="Enter document type" v-model="name" >
                    </div>
                    <div class="col-md-8">
                        <button class="btn btn-primary btn-small" @click="postDocumentType">Add</button>
                    </div>
                    <div class="col-md-2" v-if="documentDisplay =='none'">
                        <button class="btn-small btn btn-default" style="border: 1px solid #f2f2f2; float: right;" @click="documentDisplay='block', this.fetchDocumentTypes()">View List</button>
                    </div>
                </div>
            
                <div class="row mt-4" :style="{ 'display': documentDisplay }">
                    <h6>Documents list <button class="btn btn-sm btn-default pull-right text-primary" @click="documentDisplay='none'" style="float:right;">Hide List</button></h6>
                    <table class="table table-hover">
                        <thead>
                            <th>No.</th>
                            <th>Document Type </th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr v-for="(doc,index) in documentTypes" v-bind:key="doc.id">
                                <td>{{ index+1 }}</td>
                                <td>{{ doc.name}}</td>
                                <td><button class="btn-sm btn btn-danger" @click="deleteDocumentType(doc.id)">Delete</button></td>
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
                
            </div>

                    




            </div>
        </section> 
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Header from '../../components/Header';
import Swal from 'sweetalert2'
//import axios from 'axios'

export default {
  name: 'SettingsDocumentTypesPage',

  components:{
      Header
  },

  data: () => ({
    token: JSON.parse(localStorage.getItem('access_token')),
    urlServer:process.env.VUE_APP_URL,
    name: "",
    documentDisplay: "none",
  }),

  
  computed:{
    ... mapGetters (["getDocumentTypes"]),
    documentTypes () { return this.getDocumentTypes },
  },

  methods:{
    ... mapActions(['fetchDocumentTypes']),
    async postDocumentType() {
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
          body: JSON.stringify({ 
              name: this.name
          })
      };
      const res = await fetch(process.env.VUE_APP_URL+'/documents', requestOptions)
      const data = await res.json()
      if(data.status == true){
          this.documentDisplay = 'block';
          this.name = "";
          this.fetchDocumentTypes();
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Document Type not added',
              text: data.message
          })
      }
    },

    async deleteDocumentType (id) {
      const requestOptions = {
          method: "DELETE",
          headers: { 
            'Accept': 'application/json',
            'Authorization': 'Bearer '+this.token 
          },
      };
      const res = await fetch(process.env.VUE_APP_URL+'/documents/'+id, requestOptions)
      const data = await res.json()
      if(data.status == true){
          Swal.fire({
              icon: 'success',
              title: 'Document Type deleted successfully',
              text: 'Document Type  deleted'
          })
          this.fetchDocumentTypes();
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Document Type not deleted',
              text: data.message
          })
      }
    },
  }

}

</script>





