<template>
    <div class="home">
  
    <Header :mainHeader="true"/>

    <section id="settings-main">
        <div class="container" style="padding: 0px 30px">
                
            <div class="row d-flex align-items-center" id="main-header">
                <div class="col-md-4">
                    <h6 class="fw-bold mt-2">System & User  </h6>
                </div>
                <div class="col-md-4">
                    <h6 class="fw-bold mt-2">System Configurations </h6>
                </div>
                <div class="col-md-4">
                    <h6 class="fw-bold mt-2">Tools </h6>
                </div>
            </div>

            <div class="row panel" >
                <div class="col-md-4">
                    <ul>
                        <!--<li class="mb-3" @click="$router.push('/settings/business-profile')">Company Details</li>-->
                        <li class="mb-3" @click="$router.push('/settings/roles')">Roles & Permissions</li>
                        <li class="mb-3" @click="$router.push('/settings/users')">Manage Users</li>
                        <li class="mb-3"  @click="$router.push('/settings/departments')">Manage Departments</li>
                        <li class="mb-3" ><a href="https://documenter.getpostman.com/view/35239373/2sA3s1orq6" target="_blank">Integrations</a></li>
                        <li class="mb-3"  @click="$router.push('/settings/users')">Device Management</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <ul>
                        <li class="mb-3" @click="$router.push('/settings/categories')">Category setup </li>
                        <li class="mb-3" @click="$router.push('/comming-soon')">Tags Management</li>
                        <li class="mb-3" @click="$router.push('/settings/document-types')">Document Types</li>
                        <li class="mb-3" @click="$router.push('/comming-soon')">Currencies</li>
                        <li class="mb-3" @click="$router.push('/settings/approvals')">Approvals</li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <ul>
                        <li class="mb-3" @click="$router.push('/settings/audit')">Audit log</li>
                        <li class="mb-3" @click="$router.push('/settings/notifications')">Notifications</li>
                        <li class="mb-3" @click="$router.push('/settings/profile')">Profile Setup</li>
                        <!--<li class="mb-3" @click="$router.push('/settings/billing')">Billing</li>-->
                        
                    </ul>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>

import Header from '../../components/Header';
export default {
    name: 'SettingsMainPage',
    components:{
        Header
    }
}
</script>

<style lang="scss" scoped>
ul{
    display: block;
    list-style-type: none;
}
ul > li{
    color: #0c275e;
    list-style: none;
    display: block;
    padding: 3px 0px; 
}
ul li:hover{
    color:#00005C;
    cursor: pointer;
    text-decoration: underline;
}

#main-header{
    padding: 10px;
    background-color: #dce2f0;
}
</style>
