import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss';

createApp(App)
.use(router)
.use(store)
.use(VueAxios, axios)
.use(Vue3Toasity,{ autoClose: 3000 })

.mixin({
    methods: {
      formatDate: function (rawDate, timeOption) {
        if(rawDate){
          const dateNo = new Date(rawDate)
          if(timeOption == "true"){
            var minutes = 0;
            if(dateNo.getMinutes() < 10){
              minutes = "0"+dateNo.getMinutes()
            } else {
              minutes = dateNo.getMinutes()
            }
            var hours = 0;
            if(dateNo.getHours() < 10){
              hours = "0"+dateNo.getHours()
            } else {
              hours = dateNo.getHours()
            }
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
          } else {
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear();
          }
        } else {
          return ""
        }
      },

      countPendingApprovals(array) {
        let count = 0;
        array.forEach(obj => {
          if (obj.type === "pending") {
            count++;
          }
        });

        return count;
      },


      truncateString(str) {
        return str.length > 100 ? str.substring(0, 100) + '...' : str;
      },


      filterApprovals(approvals, arg) {
        if(arg === "pending")
          return approvals.filter(approval => approval.type === "pending");
        else if(arg == "processed")
          return approvals.filter(approval => approval.type != "pending");
        else if(arg == "urgent")
        return approvals.filter(approval => (approval.priority === "urgent" && approval.type === "pending"));
       
      },

      formatMoney: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat('en-US', { style: 'currency',currency: 'KES',currencyDisplay: 'narrowSymbol' }).format(value);
        } else {
          return "0.00";
        }
      },
      formatQty: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat().format(value);
        } else {
          return "0";
        }
      },
    
      async shortenUrl(url){
        console.log(url)
        const body =  {
          apiKey: "6648972db05ab3793ed113d8",
          projectName:"Test1",
          email:"dev.pasaka@gmail.com",
          longUrl:url
        }
        try{
          const res = await axios.post('https://pentagonapi.pavicontech.com/api/v1/shorty/shorten', body)
          return res.data.shortenedUrl.shortUrl
        }catch(err){
          console.log("Url Shortener Error", err)
        }

      }, 
    },
  })
.mount('#app')